<template>
  <v-app class="bg-white">
    <v-container style="text-align: center !important;">
      <v-row>
        <v-col
          :cols="$vuetify.breakpoint.smAndDown ? 12 : 4"
          :offset="$vuetify.breakpoint.smAndDown ? 0 : 4"
        >
          <div>
            <img src="/learnbread-primary-logo-removebg.png" alt="Learnbread Logo" class="logo" />
            <h2>Create an Account</h2>
            <p class="mb-1">
              Please provide the information needed.<br/>
              Already have an account ? <router-link to="/login">Login</router-link>
            </p>
          </div>
          <v-stepper v-model="step" alt-labels>
            <v-stepper-header>
              <v-stepper-step :complete="step > 1" step="1">
                Step 1
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="step > 2" step="2">
                Step 2
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="step > 3" step="3">
                Step 3
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="step > 4" step="4">
                Step 4
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <v-form @submit.prevent="nextStep(1)">
                  <v-text-field
                    v-model="formData.fullname"
                    label="Enter your Full Name"
                    required
                    :rules="[(v) => !!v || 'Full Name is required', (v) => /^[a-zA-Z ]+$/.test(v) || 'Only letters and spaces are allowed']"
                  ></v-text-field>
                  <v-btn class="mt-4 mr-2" outlined @click="nextStep(1)" :disabled="!validateStep(1)">
                    Next
                    <v-icon right>mdi-chevron-right</v-icon>
                  </v-btn>
                  <v-alert class="mt-2 l-alert" type="info" v-if="!validateStep(1)" >Your Full Name is required and this name should reflect in your bank account name. And contain only letters and spaces.</v-alert>
                </v-form>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-form @submit.prevent="nextStep(2)">
                  <v-text-field
                    v-model="formData.username"
                    label="Username"
                    required
                    :rules="[(v) => !!v || 'Username is required', (v) => /^[a-zA-Z0-9]+$/.test(v) || 'Only letters and numbers are allowed']"
                  ></v-text-field>
                  <v-btn class="mt-4 mr-2" outlined @click="prevStep">
                    <v-icon left>mdi-chevron-left</v-icon>
                    Previous
                  </v-btn>
                  
                  <v-btn class="mt-4" outlined @click="nextStep(2)" :disabled="!validateStep(2)">
                    Next
                    <v-icon right>mdi-chevron-right</v-icon>
                  </v-btn>
                  <v-alert class="mt-2 l-alert" type="info" v-if="!validateStep(2)">Username is required and should contain only letters and numbers.</v-alert>
                </v-form>
              </v-stepper-content>

              <v-stepper-content step="3">
                <v-form @submit.prevent="nextStep(3)">
                  <v-text-field
                    v-model="lowercaseEmail"
                    label="Email"
                    required
                    :rules="[(v) => !!v || 'Email is required', (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || 'Invalid email format']"
                  ></v-text-field>
                  <v-btn class="mt-4 mr-2" outlined @click="prevStep">
                    <v-icon left>mdi-chevron-left</v-icon>

                    Previous
                  </v-btn>
                  <v-btn class="mt-4" outlined @click="nextStep(3)" :disabled="!validateStep(3)">
                    Next
                    <v-icon right>mdi-chevron-right</v-icon>
                  </v-btn>
                  <v-alert v-if="!validateStep(3)" class="mt-2 l-alert" type="info">Enter a valid email address.</v-alert>
                </v-form>
              </v-stepper-content>

              <v-stepper-content step="4">
                <v-form @submit.prevent="submitForm">
                  <v-text-field
                    v-model="formData.password"
                    label="Password"
                    :type="showPassword ? 'text' : 'password'"
                    required
                    :rules="[
                      (v) => !!v || 'Password is required',
                      (v) => /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/.test(v) || 'Password must be at least 8 characters and contain at least one letter and one number'
                    ]"
                    append-icon="mdi-eye"
                    @click:append="togglePasswordVisibility"
                  ></v-text-field>
                  <v-btn class="mt-4 mr-2" outlined @click="prevStep" :disabled="submitting || success">
                    <v-icon left>mdi-chevron-left</v-icon>
                    Previous
                  </v-btn>
                  <v-btn class="mt-4" outlined @click="submitForm" :disabled="submitting || success">
                    <template v-if="submitting">Submitting...</template>
                    <template v-else>Submit<v-icon right>mdi-chevron-right</v-icon></template>
                  </v-btn>
                  <v-alert class="mt-2 l-alert" v-if="!validateStep(4)" type="info">Password must be at least 8 characters and alphanumeric.</v-alert>
                  <v-alert class="mt-5" v-if="success" type="success" color="green">{{ successMessage }}</v-alert>
                  <v-alert class="mt-5" v-if="error" type="error" color="red">{{ errorMessage }}</v-alert>
                </v-form>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      step: 1,
      formData: {
        fullname: '',
        username: '',
        email: '',
        password: ''
      },
      submitting: false,
      success: false,
      successMessage: '',
      error: false,
      errorMessage: '',
      showPassword: false,
      showvalidateerror: false
    }
  },
  computed: {
    lowercaseEmail: {
      get() {
        return this.formData.email.toLowerCase()
      },
      set(value) {
        this.formData.email = value
      }
    }
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword
    },
    nextStep(step) {
      // Add validation logic for each step
      if (this.validateStep(step)) {
        this.step = step + 1
      }
    },
    prevStep() {
      // Move to the previous step
      this.step -= 1
    },
    validateStep(step) {
      // Add validation logic for each step
      switch (step) {
      case 1:
        
        return this.formData.fullname.length > 0 && /^[a-zA-Z ]+$/.test(this.formData.fullname)
      case 2:

        return this.formData.username.length > 0 && /^[a-zA-Z0-9]+$/.test(this.formData.username)
      case 3:
        return this.formData.email.length > 0 && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.formData.email)
      case 4:
        return (
          this.formData.password.length > 0 &&
          /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/.test(
            this.formData.password
          )
        )
      default:
        return true
      }
    },
    async submitForm() {
      // Set submitting to true to disable buttons during form submission
      this.submitting = true

      try {
        // Prepare form data
        const formData = new FormData()

        formData.append('fullname', this.formData.fullname)
        formData.append('username', this.formData.username.toLowerCase())
        formData.append('email', this.formData.email.toLowerCase())
        formData.append('password', this.formData.password)

        // Perform the form submission using Fetch API
        const response = await fetch(`${process.env.VUE_APP_BASE_SERVICE_URL_LOCAL}/api/auth/register`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            fullname: this.formData.fullname,
            username: this.formData.username,
            email: this.formData.email,
            password: this.formData.password
          })
        })

        // Check if the response status is okay (2xx)
        if (response.ok) {
          const responseData = await response.json()
          // Set success to true to show success message

          this.success = true
          this.successMessage = responseData.message
          this.error = false
        } else {
          // Set success to false to keep buttons active in case of error
          this.success = false

          // Extract and set error message from the response
          const responseData = await response.json()
          
          this.errorMessage = responseData.error || 'An error occurred. Please try again.'
          this.error = true
        }
      } catch (error) {
        // Set success to false to keep buttons active in case of error
        this.success = false
        this.errorMessage = responseData.error || 'An error occurred. Please try again.'
        this.error = true
        // Set error message to display in the form
        //this.errorMessage = 'An error occurred. Please try again.'
      } finally {
        // Set submitting back to false to re-enable buttons
        this.submitting = false
      }
    }
  }
}
</script>

<style scoped>
/* Add your custom styles here */

.logo {
  width: 100px; /* Adjust the width as needed */
  height: auto; /* Maintain the aspect ratio */
  margin-bottom: 5px;
}

body {
  background-color: white !important;
}

.l-alert {
  background-color: #ecb68a !important;
  color:#603913 !important;
  margin-bottom: 0px !important;
}

.bg-white {
  background-color: white !important;
}
</style>
